document.addEventListener('DOMContentLoaded', function () {
  const ajaxUrl = `${window.location.origin}/wp/wp-admin/admin-ajax.php`
  const movieContainer = document.querySelector('.movie-container')
  if (!movieContainer) {
    return
  }
  const allButton = document.querySelector('.filter-js-all')
  const nowButton = document.querySelector('.filter-js-now')
  const upcomingButton = document.querySelector('.filter-js-upcoming')

  function fetchMovies(filter) {
    try {
      fetch(ajaxUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          action: 'filter_movies',
          filter: filter,
          language: 'en',
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then((data) => {
          movieContainer.innerHTML = data.data
        })
        .catch((error) => {
          console.error('There was a problem with the fetch operation:', error)
        })
    } catch (error) {
      console.error('An error occurred while trying to fetch movies:', error)
    }
  }

  function addFilterEventListeners() {
    if (nowButton && upcomingButton) {
      nowButton.addEventListener('click', function () {
        fetchMovies('now')
      })
      upcomingButton.addEventListener('click', function () {
        fetchMovies('upcoming')
      })
      allButton.addEventListener('click', function () {
        // Fetch all movies without applying any filters
        fetchMovies('all')
      })
    }
  }

  // Add event listeners for filtering
  addFilterEventListeners()
})
